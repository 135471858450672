import styled from 'styled-components';
import { Text } from '@strikelabs/luna';

export const Outer = styled.div`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.xl} 0`};

  ${({ theme }) => theme.media.desktop} {
    margin: ${({ theme }) => `${theme.spacing.xxl} 0`};
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};
`;

export const CarouselWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const Title = styled(Text).attrs({
  as: 'h2',
  styleType: 'h1',
  ext: true,
})`
  margin-top: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const MaxWidthWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`;
