import React, { useEffect } from 'react';
import { Subheading } from 'components/common';

import { Title, Outer, Inner, MaxWidthWrapper } from './style';

const Reviews = () => {
  const ref = React.useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <Outer>
      <Inner>
        <Subheading>Don&apos;t take our word for it</Subheading>
        <Title>
          Stories from <br />
          our customers
        </Title>
      </Inner>
      <MaxWidthWrapper>
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="55ca14bb0000ff0005820737"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-review-languages="en"
        >
          <a
            href="https://uk.trustpilot.com/review/strike.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </MaxWidthWrapper>
    </Outer>
  );
};

export default Reviews;
