import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { identifier } from '@strikelabs/pax';
import { getSearchParams } from 'gatsby-query-params';
import { set } from 'tiny-cookie';
import { Underline } from '@strikelabs/luna';

import { CHECKLIST } from 'constants/sell-your-home-for-free';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HowWeSellForFree from 'components/HowWeSellForFree';
import ReviewsV1 from 'components/ReviewsV1';
import SavingsSlider from 'components/SavingsSlider';
import Checklist from 'components/Checklist';
import {
  Hero,
  Guides,
  RafModal,
  PropertySearch,
  ESTAS,
  ChangingWhatItMeans,
} from 'components/Pages/Homepage/Sections';
import { Portals, Disclaimer } from 'components/Pages/LandingPages/Sections';
import BrandImg from 'images/strike-brand.png';
import { TYPES } from 'schemas';

const Homepage = () => {
  const urlParams = getSearchParams();
  const [showRafModal, setShowRafModal] = useState(false);

  useEffect(() => {
    if (urlParams.mbsy) {
      set('ambassador_referrer_id', urlParams.mbsy, { expires: '1M' });
      setShowRafModal(true);
    }
  }, [urlParams]);

  return (
    <>
      <Layout>
        <SEO
          title="Strike - Online Estate Agents. Sell your house for FREE"
          description="Sell Your House For Free With A Simple And Transparent Online Estate Agency Service. Go On Strike. Strike A Better Deal."
          canonical="/"
          metaImage={BrandImg}
          schemaType={TYPES.HOME}
        />
        <div>
          <Hero subtitle=" " hidePortals>
            Let&apos;s sell your home
            <br />
            <Underline
              weight="h1"
              lineMultiplier={0.3}
              active
              auto
              delay={1}
              duration={1}
            >
              for free
            </Underline>
          </Hero>
        </div>
        <Portals />
        <LazyLoad height={785} offset={80}>
          <ESTAS
            title="We sell your home faster — and for more — than any other top UK agent.*"
            subtitle="There's a reason we're the Online Estate Agent of the Year for three years running."
            noCta
            bgColor="white"
          />
        </LazyLoad>
        <LazyLoad height={672} offset={80}>
          <ChangingWhatItMeans bgColor="backgroundLight" />
        </LazyLoad>
        <SavingsSlider title="See how much you could save when you sell your home for free" />
        <LazyLoad height={917} offset={80}>
          <Checklist
            title="What's included for free with Strike"
            list={CHECKLIST}
          />
        </LazyLoad>
        <LazyLoad height={661} offset={80}>
          <HowWeSellForFree />
        </LazyLoad>
        <LazyLoad height={640} offset={80}>
          <ReviewsV1 />
        </LazyLoad>
        <div {...identifier({ name: 'property-search', requireId: false })}>
          <LazyLoad height={663} offset={80}>
            <PropertySearch />
          </LazyLoad>
        </div>
        <LazyLoad height={1377} offset={80}>
          <Guides />
        </LazyLoad>
        <Disclaimer />
        <RafModal showModal={showRafModal} setShowModal={setShowRafModal} />
      </Layout>
    </>
  );
};

export default Homepage;
